import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import { Button, List, Cell } from 'vant'
import axios from 'axios'
Vue.use(VueParticles)
Vue.use(Button)
Vue.use(List)
Vue.use(Cell)
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
